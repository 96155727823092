<template>

<div class="mt-5 h-fit grid grid-cols-9 grid-rows-5
            text-xs md:text-sm lg:ext-sm xl:text-base 2xl:text-xl ">
      <button class="bg-blue-500 disabled:bg-blue-500 hover:bg-blue-700  text-white font-bold 
                     mt-1 w-full h-10 py-2 px-4 rounded col-span-7 col-start-2"
              type="button"
              :disabled="!selected_biopid"
              @click="emitState(1)">
        Map
      </button>
      <button class="bg-hcap_brown disabled:bg-hcap_brown hover:bg-blue-700 text-white font-bold 
                     mt-1 w-full h-10 py-2 px-4 rounded col-span-7 col-start-2"
              type="button"
              :disabled="!selected_biopid"
              @click="emitState(2)">
        Patch Data
      </button>
      <button class="bg-hcap_blue disabled:bg-hcap_blue hover:bg-blue-700 text-white font-bold 
                     mt-1 w-full h-10 py-2 px-4 rounded col-span-7 col-start-2"
              type="button"
              :disabled="!selected_biopid"
              @click="emitState(3)">
        Habitat Components
      </button>
      <button class="bg-hcap_green disabled:bg-hcap_green hover:bg-blue-700 text-white font-bold 
                     mt-1 w-full h-10 py-2 px-4 rounded col-span-7 col-start-2"
              type="button"
              :disabled="!selected_biopid"
              @click="emitState(4)">
        Habitat Change
      </button>
      <button class="bg-hcap_dk_blue disabled:bg-hcap_dk_blue hover:bg-blue-700 text-white font-bold 
                     mt-1 w-full h-10 py-2 px-4 rounded col-span-7 col-start-2"
              type="button"
              :disabled="!selected_biopid"
              @click="emitState(5)">
        Graphs
      </button>
    </div>
</template>

<script>


export default {

  name: "SelectComponent",

  props:[
      'selected_biopid'
    ],

  emits:[
    'state'
  ],

  methods:{
    emitState(value){
      this.$emit('state', value);
    }
  }

}

</script>