<template>
  <div v-show="show"
       id="graph-window"
       class="border-2  h-full border-neutral-100 hover:shadow--xl"
       :class="{'bg-hcap_dk_blue/10': !selected_biopid,
                'bg-white': selected_biopid}">
    <div class="grid grid-cols-2 w-full">
      <div class="align-items-center col-span-2 grid grid-cols-12
                justify-content-between justify-start gap-2 bg-hcap_dk_blue">

        <span class="col-span-12 w-full text-center text-white">Graphs</span>

        <!-- <div class="h-6 col-span-1 inline text-white pr-0.5"
            style="font-variation-settings: 'wght' 700, 'GRAD' 0, 'opsz' 48;"
            id="table-expander">
          <span class="font-symbol material-symbols-outlined w-6 float-right"
                v-show="!full_screen"   
                @click="full_screen = true">fullscreen</span> 
          <span class="font-symbol material-symbols-outlined w-6 float-right"
                v-show="full_screen"
                @click="full_screen = false">fullscreen_exit</span>
        </div> -->

      </div>
      <div v-show="show_timer && !server_error"
        class="col-span-12 mt-10 inline h-8 w-8 animate-spin rounded-full
               border-4 border-solid border-current border-e-transparent 
               border-hcap_blue align-middle mx-auto
               motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
          <span class="!absolute !-m-px !h-px !w-px !overflow-hidden 
                 !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >Loading...</span >
      </div>
      <div class="h-full w-full flex items-center text-red-600"
          v-show="server_error">
        An error has occured.  Try refreshing the page.
      </div>

      <div class="card col-span-2" id="chart" v-show="show_graph">
        <section>
            <div class="flex flex-col align-items-center justify-content-between
                        col-span-2 border-b border-hcap_logo_green py-1"> 
              <div>
              <ul class="flex flex-wrap items-center justify-center
                         font-normal text-blue-700 dark:text-white">
                  <li>
                      <a href="#" 
                         class="me-4 hover:underline hover:text-blue-500 
                                md:me-6"
                          :class="{'underline font-bold': dataset=='patches'}"
                         data-id="patches"
                         @click="prepareGraph('patches')">Patches</a>
                  </li>
                  <li>
                      <a href="#" 
                         class="me-4 hover:underline hover:text-blue-500 
                                md:me-6"
                          :class="{'underline font-bold': dataset=='class'}"
                         data-id="class"
                         @click="prepareGraph('class')">Class</a>
                  </li>
                  <li>
                      <a href="#" 
                         class="me-4 hover:underline hover:text-blue-500 
                                md:me-6"
                          :class="{'underline font-bold': dataset=='category'}"
                         data-id="category"
                         @click="prepareGraph('category')">Category</a>
                  </li>
                  <li>
                      <a href="#" 
                         class="me-4 hover:underline hover:text-blue-500 
                                md:me-6"
                          :class="{'underline font-bold': 
                                    dataset=='succession_type'}"
                         data-id="succession_type"
                         @click="prepareGraph('succession_type')">Succession</a>
                  </li>
                  <li>
                      <a href="#" 
                         class="me-4 hover:underline hover:text-blue-500 
                                md:me-6"
                          :class="{'underline font-bold': 
                                    dataset=='riparian'}"
                         data-id="riparian"
                         @click="prepareGraph('riparian')">Riparian</a>
                  </li>
              </ul>
              </div>
            </div>
          </section>
          <apexchart type="line" class="w-full h-full" 
                    :options="chartOptions" 
                    :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from "vue3-apexcharts";

  export default {
                
    name: 'GraphComponent',

    components: {
      'apexchart': VueApexCharts
    },

    props:[
      'biopid_data',
      'parent_height',
      'selected_biopid',
      'server_error',
      'show_timer',
      'species_name',
      'visible'
    ],
            
    emits: [
      'state',
    ],

    watch:{
      full_screen: function(){
        this.$emit('state', this.full_screen?5:1);
      },

      selected_biopid: function(){
          if(!this.selected_biopid){
            this.clearChart();
            return;
          }
      },

      biopid_data: {
        deep: true,
        handler(){
          if(!this.biopid_data){
            return;
          }
          this.show_graph = true;
          this.prepareGraph();
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      },
    },

    data(){
      return{
        chartOptions: {},
        dataset: null,
        default_dataset: 'patches',
        full_screen: false,
        height: 0,
        series: [],
        show: true,
        show_graph: false,
        show_menu: false,
        stats: {},
        yrs: [1986, 1995, 2002, 2007, 2012, 2015, 2020]
      }
    },

    mounted(){
      this.addResizer();
    },

    methods:{

      addResizer(){
        const observer = new ResizeObserver(()=> {
          this.height = document.getElementById('graph-window').offsetHeight/1.5;
          if(this.selected_biopid){
            this.prepareGraph();
          }
        });
        observer.observe(document.getElementById('graph-window'));
      },

      clearChart(){
        this.series = [];
        this.dataset = null;
        this.show_graph = false;
        this.show_menu = false;
      },

      prepareGraph(dataset=null){
        if(dataset){
          this.dataset = dataset;
        }else{
          this.dataset = this.default_dataset;
        }

        switch(this.dataset){
          case 'patches':
            this.graphPatches();
            break;
          case 'class':
            this.graphClass();
            break;
          case 'category':
            this.graphCategory();
            break;
          case 'succession_type':
            this.graphSuccession();
            break;
          case 'riparian':
            this.graphRiparian();
            break;
          default:
            this.graphPatches();
        }

        this.show_menu = true;
      },

      graphPatches(){
        const src = this.biopid_data[this.dataset];
            let series = [];
            let options = {};
            let s1 = [];
            let s2 = [];
        
        for(let i=0;i<7;i++){
          s1.push(src[i]['patch_count']);
          s2.push(src[i]['total_area']);
        }

        series.push({name: 'Count', data: s1});
        series.push({name: 'Acres', data: s2});

        options['chart'] = {
          height: this.height,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        options['dataLabels'] = {
          enabled: false
        },

        options['colors'] = ['#be2196', '#19c9fe'],
                                       
        options['stroke'] = {
          width: 4,
          curve: 'straight',
          dashArray: 0
        },

        options['title'] =  {
          text: this.species_name + ' Contiguous Habitat Areas',
          align: 'left',
          offsetY: 10,
          offsetX: 40,
          style: {
            color:  'rgba(33, 46, 108, .5)'
          },
        },

        options['xaxis'] = {
          categories: this.yrs,
          title: {
              text: 'Year'
          }
        },

        options['yaxis'] = [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#000f8a"
            },
            labels: {
              formatter: function (value) {
                return Math.round(value).toLocaleString()
              },
              style: {
                colors: "#000f8a"
              }
            },
            title: {
              text: "Count",
              style: {
                color: "#000f8a"
              }
            }
          },
          {
            opposite: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#7e495e"
            },
            title: {
              text: "Area (acres)",
              style: {
                color: "#7e495e"
              }
            },
            labels: {
              formatter: function (value) {
                return Math.round(value).toLocaleString()
              },
              style: {
                colors: "#7e495e"
              }
            }
          }
        ],

        options['markers'] = {
          size: 6,
          colors: ['#be2196', '#19c9fe'],
          strokeColors:['#be2196', '#19c9fe'],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: 8,
            sizeOffset: 3
          }
        },

        options['grid'] = {
          borderColor: '#f1f1f1',
          xaxis: {
            'lines': {show: true}
          }
        },
        
        this.series = series;
        this.chartOptions = options;
      },

      graphClass(){
        const src = this.biopid_data[this.dataset];
          let series = [];
          let options = {};
          let agriculture = [];
          let natural = [];
          let urban = [];
          let agriculture_pct = [];
          let natural_pct = [];
          let urban_pct = [];
        
        for(const i of src){
          switch(i.class){
            case 'Agriculture':
              agriculture.push(i.area);
              agriculture_pct.push(i.pct);
              break;
            case 'Natural':
              natural.push(i.area);
              natural_pct.push(i.pct);
              break;
            case 'Urban':
              urban.push(i.area);
              urban_pct.push(i.pct);
              break;
          }
        }

        series.push({name: 'Agriculture', data: agriculture});
        series.push({name: 'Natural', data: natural});
        series.push({name: 'Urban', data: urban});

        options['chart'] = {
          height: this.height,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        options['dataLabels'] = {
          enabled: false
        },

        options['colors'] = ['#a6ffa5', '#018200', '#FF0000'],
                                      
        options['stroke'] = {
          width: 4,
          curve: 'straight',
          dashArray: 0
        },

        options['title'] =  {
          text: this.species_name + ' Habitat by Class',
          align: 'left',
          offsetY: 10,
          offsetX: 40,
          style: {
            color:  'rgba(33, 46, 108, .5)'
          },
        },

        options['xaxis'] = {
           categories: this.yrs,
          title: {
              text: 'Year'
          }
        },

        options['yaxis'] = [
          
          {
            opposite: false,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#247BA0"
            },
            title: {
              text: "Area (acres)",
              style: {
                color: "#247BA0"
              }
            },
            labels: {
              formatter: function (value) {
                return Math.round(value).toLocaleString()
              },
              style: {
                colors: "#247BA0"
              }
            }
          }
        ],

        options['markers'] = {
          size: 6,
          colors: ['#a6ffa5', '#018200', '#FF0000'],
          strokeColors:['#a6ffa5', '#018200', '#FF0000'],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: 8,
            sizeOffset: 3
          }
        },

        options['grid'] = {
          borderColor: '#f1f1f1',
          xaxis: {
            'lines': {show: true}
          }
        },
        
        this.series = series;
        this.chartOptions = options;
      },

      graphCategory(){

        const src = this.biopid_data[this.dataset];
      
        let series = [];
        let options = {};
        let BARREN = Array(7).fill(0);
        let BEACH_DUNE = Array(7).fill(0);
        let GRASSLAND = Array(7).fill(0);
        let NON_HABITAT = Array(7).fill(0);
        let SHRUB_UPLAND = Array(7).fill(0);
        let SHRUB_WETLAND = Array(7).fill(0);
        let TIDAL_WATER = Array(7).fill(0);
        let UPLAND_FOREST_CON = Array(7).fill(0);
        let UPLAND_FOREST_DEC = Array(7).fill(0);
        let UPLAND_FOREST_MIX = Array(7).fill(0);
        let URBANHAB = Array(7).fill(0);
        let WATER = Array(7).fill(0);
        let WETCOAST = Array(7).fill(0);
        let WETDIST = Array(7).fill(0);
        let WETEMERG = Array(7).fill(0);
        let WETLAND_FOREST_CON = Array(7).fill(0);
        let WETLAND_FOREST_DEC = Array(7).fill(0);
        let WETLAND_FOREST_MIX = Array(7).fill(0);
        
        for(const i of src){
          switch(i.category){
            case 'Barren':
              BARREN[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Beach-Dune':
              BEACH_DUNE[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Grassland':
              GRASSLAND[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Non-Habitat':
              NON_HABITAT[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Shrub Upland':
              SHRUB_UPLAND[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Shrub Wetland':
              SHRUB_WETLAND[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Tidal Water':
              TIDAL_WATER[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Upland Forest Con':
              UPLAND_FOREST_CON[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Upland Forest Dec':
              UPLAND_FOREST_DEC[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Upland Forest Mix':
              UPLAND_FOREST_MIX[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Urbanhab':
              URBANHAB[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Water':
              WATER[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Wetcoast':
              WETCOAST[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Wetdist':
              WETDIST[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Wetemerg':
              WETEMERG[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Wetland Forest Con':
              WETLAND_FOREST_CON[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Wetland Forest Dec':
              WETLAND_FOREST_DEC[this.yrs.indexOf(i.year)] = i.area;
              break;
            case 'Wetland Forest Mix':
              WETLAND_FOREST_MIX[this.yrs.indexOf(i.year)] = i.area;
              break;
          }
        }

        const stack = [
          ['Barren',BARREN,BARREN.reduce((a, b) => a + b, 0)],
          ['Beach-Dune',BEACH_DUNE,BEACH_DUNE.reduce((a, b) => a + b, 0)],
          ['Grassland',GRASSLAND,GRASSLAND.reduce((a, b) => a + b, 0)],
          ['Non-Habitat',NON_HABITAT,NON_HABITAT.reduce((a, b) => a + b, 0)],
          ['Shrub Upland',SHRUB_UPLAND,SHRUB_UPLAND.reduce((a, b) => a + b, 0)],
          ['Shrub Wetland',SHRUB_WETLAND,
                                      SHRUB_WETLAND.reduce((a, b) => a + b, 0)],
          ['Tidal Water',TIDAL_WATER,TIDAL_WATER.reduce((a, b) => a + b, 0)],
          ['Upland Forest Con',UPLAND_FOREST_CON,
                                  UPLAND_FOREST_CON.reduce((a, b) => a + b, 0)],
          ['Upland Forest Dec',UPLAND_FOREST_DEC,
                                  UPLAND_FOREST_DEC.reduce((a, b) => a + b, 0)],
          ['Upland Forest Mix',UPLAND_FOREST_MIX,
                                  UPLAND_FOREST_MIX.reduce((a, b) => a + b, 0)],
          ['Urbanhab',URBANHAB,URBANHAB.reduce((a, b) => a + b, 0)],
          ['Water',WATER,WATER.reduce((a, b) => a + b, 0)],
          ['Wetcoast',WETCOAST,WETCOAST.reduce((a, b) => a + b, 0)],
          ['Wetdist',WETDIST,WETDIST.reduce((a, b) => a + b, 0)],
          ['Wetemerg',WETEMERG,WETEMERG.reduce((a, b) => a + b, 0)],
          ['Wetland Forest Con',WETLAND_FOREST_CON,
                                WETLAND_FOREST_CON.reduce((a, b) => a + b, 0)],
          ['Wetland Forest Dec',WETLAND_FOREST_DEC,
                                WETLAND_FOREST_DEC.reduce((a, b) => a + b, 0)],
          ['Wetland Forest Mmix',WETLAND_FOREST_MIX,
                                WETLAND_FOREST_MIX.reduce((a, b) => a + b, 0)]
        ];
        stack.sort(function(a, b){if (a[2] === b[2]) {return 0 }
                                  else {return (a[2] < b[2]) ? -1 : 1; }});
        let arr_sum = Array(7).fill(0);
        for(let i = 17; i>=0; i--){
          if(i>9){
            series.push({name: stack[i][0], data: stack[i][1] });
          }else{
            for(let q = 0; q<7; q++){
              arr_sum[q] += stack[i][1][q];
            }
          }
        }

        series.push({name:'10 Others', data: arr_sum });

        options['chart'] = {
          height: this.height,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        options['dataLabels'] = {
          enabled: false
        },

        options['colors'] = ["#ed5151ff", "#149eceff", "#a7c636ff", "#9e559cff", 
                             "#fc921fff", "#ffde3eff", "#f789d8ff", "#b7814aff", 
                             "#3caf99ff", "#6b6bd6ff", "#b54779ff", "#7f7f7fff",
                             "#007fd9ff", "#443dbfff", "#881fc5ff", "#d43f70ff", 
                             "#d9874cff", "#b6a135ff", "#c4dc66ff", "#ebe498ff"
                            ],
                                      
        options['stroke'] = {
          width: 4,
          curve: 'straight',
          dashArray: 0
        },

        options['title'] =  {
          text: this.species_name + ' Habitat by Category',
          align: 'left',
          offsetY: 10,
          offsetX: 40,
          style: {
            color:  'rgba(33, 46, 108, .5)'
          },
        },

        options['xaxis'] = {
           categories: this.yrs,
          title: {
              text: 'Year'
          }
        },

        options['yaxis'] = [
          
          {
            opposite: false,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#247BA0"
            },
            title: {
              text: "Area (acres)",
              style: {
                color: "#247BA0"
              }
            },
            labels: {
              formatter: function (value) {
                return Math.round(value).toLocaleString()
              },
              style: {
                colors: "#247BA0"
              }
            }
          }
        ],

        options['markers'] = {
          size: 6,
          colors: ["#ed5151ff", "#149eceff", "#a7c636ff", "#9e559cff", 
                             "#fc921fff", "#ffde3eff", "#f789d8ff", "#b7814aff", 
                             "#3caf99ff", "#6b6bd6ff", "#b54779ff", "#7f7f7fff",
                             "#007fd9ff", "#443dbfff", "#881fc5ff", "#d43f70ff", 
                             "#d9874cff", "#b6a135ff", "#c4dc66ff", "#ebe498ff"
                            ],
          strokeColors: ["#ed5151ff", "#149eceff", "#a7c636ff", "#9e559cff", 
                             "#fc921fff", "#ffde3eff", "#f789d8ff", "#b7814aff", 
                             "#3caf99ff", "#6b6bd6ff", "#b54779ff", "#7f7f7fff",
                             "#007fd9ff", "#443dbfff", "#881fc5ff", "#d43f70ff", 
                             "#d9874cff", "#b6a135ff", "#c4dc66ff", "#ebe498ff"
                            ],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: 8,
            sizeOffset: 3
          }
        },

        options['grid'] = {
          borderColor: '#f1f1f1',
          xaxis: {
            'lines': {show: true}
          }
        },
        
        this.series = series;
        this.chartOptions = options;
      },
    
      graphSuccession(){
        const src = this.biopid_data[this.dataset];
          let series = [];
          let options = {};
          let early_successional = [];
          let forest = [];
          let mid_successional = [];
          let other_natural = [];
          let urban = [];
        
        for(const i of src){
          switch(i.succession_type){
            case 'Early Successional':
            early_successional.push(i.area);
              break;
            case 'Mid-Successional':
            mid_successional.push(i.area);
              break;
            case 'Other Natural':
            other_natural.push(i.area);
              break;
            case 'Forest':
            forest.push(i.area);
              break;
            case 'Urban':
              urban.push(i.area);
              break;
          }
        }

        series.push({name: 'Early Successional', data: early_successional});
        series.push({name: 'Mid-Successional', data: mid_successional});
        series.push({name: 'Other Natural', data: other_natural});
        series.push({name: 'Forest', data: forest});
        series.push({name: 'Urban', data: urban});

        options['chart'] = {
          height: this.height,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        options['dataLabels'] = {
          enabled: false
        },

        options['colors'] = ["#f22f00", "#26ffff", "#ffdd00", "#5ff500", 
                            "#0040ff"];

                                      
        options['stroke'] = {
          width: 4,
          curve: 'straight',
          dashArray: 0
        },

        options['title'] =  {
          text: this.species_name + ' Habitat by Succession Type',
          align: 'left',
          offsetY: 10,
          offsetX: 40,
          style: {
            color:  'rgba(33, 46, 108, .5)'
          },
        },

        options['xaxis'] = {
          categories: this.yrs,
          title: {
              text: 'Year'
          }
        },

        options['yaxis'] = [
          
          {
            opposite: false,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#247BA0"
            },
            title: {
              text: "Area (acres)",
              style: {
                color: "#247BA0"
              }
            },
            labels: {
              formatter: function (value) {
                return Math.round(value).toLocaleString()
              },
              style: {
                colors: "#247BA0"
              }
            }
          }
        ],

        options['markers'] = {
          size: 6,
          colors: ["#f22f00", "#26ffff", "#ffdd00", "#5ff500", "#0040ff"],
          strokeColors:["#f22f00", "#26ffff", "#ffdd00", "#5ff500", "#0040ff"],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: 8,
            sizeOffset: 3
          }
        },

        options['grid'] = {
          borderColor: '#f1f1f1',
          xaxis: {
            'lines': {show: true}
          }
        },
        
        this.series = series;
        this.chartOptions = options;
      },

      graphRiparian(){
        const src = this.biopid_data[this.dataset];
          let series = [];
          let options = {};
          let riparian = [];
          let not_riparian = [];
        
        for(const i of src){
          switch(i.riparian){
            case 'Yes':
            riparian.push(i.area);
              break;
            case 'No':
              not_riparian.push(i.area);
              break;
          }
        }

        series.push({name: 'Riparian', data: riparian});
        series.push({name: 'Non-Riparian', data: not_riparian});

        options['chart'] = {
          height: this.height,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        options['dataLabels'] = {
          enabled: false
        },

        options['colors'] = ['#6b78ff','#a6ffa5'],
                                      
        options['stroke'] = {
          width: 4,
          curve: 'straight',
          dashArray: 0
        },

        options['title'] =  {
          text: this.species_name + ' Habitat by Class',
          align: 'left',
          offsetY: 10,
          offsetX: 40,
          style: {
            color:  'rgba(33, 46, 108, .5)'
          },
        },

        options['xaxis'] = {
          categories: this.yrs,
          title: {
              text: 'Year'
          }
        },

        options['yaxis'] = [
          
          {
            opposite: false,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#247BA0"
            },
            title: {
              text: "Area (acres)",
              style: {
                color: "#247BA0"
              }
            },
            labels: {
              formatter: function (value) {
                return Math.round(value).toLocaleString()
              },
              style: {
                colors: "#247BA0"
              }
            }
          }
        ],

        options['markers'] = {
          size: 6,
          colors: ['#6b78ff','#a6ffa5'],
          strokeColors:['#6b78ff','#a6ffa5'],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: 8,
            sizeOffset: 3
          }
        },

        options['grid'] = {
          borderColor: '#f1f1f1',
          xaxis: {
            'lines': {show: true}
          }
        },
        
        this.series = series;
        this.chartOptions = options;
      }
    },                                   
  }

</script>