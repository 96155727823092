<template>
  <div class="grid grid-cols-4 grid-rows-5 w-screen h-screen bg-stone-50">
    <div class="relative top-0 col-start-2 col-span-3 row-span-2 h-screen"
        v-show="state==1">
      <map-component
        v-bind:parent_height="element_height"
        v-bind:resize_map="resize_map"
        v-bind:selected_overlay="selected_overlay"
        v-bind:selected_biopid="selected_biopid"
        v-bind:server_error="server_error"
        v-bind:visible="visible"
        @basemap_change="updateBasemapChange"
        @state="updateContentDisplay"
        @map="updateMap"
        @map_loaded="updateMapLoaded"
        @opacity="updateFeatureOpacity"
        @popup="updatePopup"/>
    </div>
    <div class="relative top-0 col-start-2 col-span-3 row-span-2 h-screen"
        v-show="state==2">
        <patches-component
            v-bind:biopid_data="biopid_data"
            v-bind:parent_height="element_height"
            v-bind:selected_biopid = "selected_biopid"
            v-bind:server_error="server_error"
            v-bind:show_timer="show_timer"
            v-bind:species_name="species_name"
            v-bind:visible="visible"
            @state="updateContentDisplay"/> 
    </div>
    <div class="relative top-0 col-start-2 col-span-3 row-span-2 h-screen"
        v-show="state==3">
        <composition-component
            v-bind:biopid_data="biopid_data"
            v-bind:parent_height="element_height"
            v-bind:selected_biopid = "selected_biopid"
            v-bind:server_error="server_error"
            v-bind:show_timer="show_timer"
            v-bind:species_name="species_name"
            v-bind:visible="visible"
            @state="updateContentDisplay"/> 
    </div>        
    <div class="relative top-0 col-start-2 col-span-3 row-span-2 h-screen"
        v-show="state==4">
        <category-component
            v-bind:biopid_data="biopid_data"
            v-bind:parent_height="element_height"
            v-bind:selected_biopid = "selected_biopid"
            v-bind:server_error="server_error"
            v-bind:show_timer="show_timer"
            v-bind:species_name="species_name"
            v-bind:visible="visible"
            @state="updateContentDisplay"/> 
    </div>       
    <div class="relative top-0 col-start-2 col-span-3 row-span-2 h-screen"
        v-show="state==5">
          <graph-component
            v-bind:biopid_data="biopid_data"
            v-bind:parent_height="element_height"
            v-bind:selected_biopid="selected_biopid"
            v-bind:server_error="server_error"
            v-bind:show_timer="show_timer"
            v-bind:species_name="species_name"
            v-bind:visible="visible"
            @state="updateContentDisplay"/>
    </div>            
    <div class=" top-0 left-0 col-start-1 col-span-1 row-start-1 row-span-5">

        <menu-component
          v-bind:basemap_change = "basemap_change"
          v-bind:map = "map"
          v-bind:map_loaded = "map_loaded"
          v-bind:feature_opacity = "feature_opacity"
          v-bind:popup = "popup"
          v-bind:selected_overlay = "selected_overlay"
          v-bind:server_error = "server_error"
          v-bind:visible="visible"
          @selected_biopid="changeSelection"
          @state="updateContentDisplay"/>          
 
    </div>           
  </div>
</template>

<script>

  import axios from 'axios';

  import CategoryComponent from "./CategoryComponent.vue" 
  import CompositionComponent from "./CompositionComponent.vue" 
  import GraphComponent from "./GraphComponent.vue" 
  import habitat_list from '../assets/data/habitat.json'
  import MapComponent from "./MapComponent.vue" 
  import MenuComponent from "./MenuComponent";
  import PatchesComponent from "./PatchesComponent";

  export default {
    name: 'TestComponent',

    components:{
      "category-component": CategoryComponent,
      "composition-component": CompositionComponent,
      "graph-component": GraphComponent,
      "map-component": MapComponent,
      'menu-component': MenuComponent,
      'patches-component': PatchesComponent
    },

    props:[
      'selected_overlay',
    ],

    data() {
      return {
        basemap_change: false,
        biopid_data: null,
        habitat_list,
        feature_opacity: .8,
        map: null,
        map_loaded: false,
        popup: null,
        resize_map: false,
        selected_biopid: null,
        server_error: null,
        show_timer: false,
        species_name: null,
        state: 1,
        visible:{}
      }
    },

    computed: {
      element_height: function () {
        const header_height = document.getElementById('header')?document
                              .getElementById('header').offsetHeight:0;
        const client_height = document.documentElement.clientHeight;
        
        return client_height - header_height;
      },

      element_height_class: function(){
        return  `h-[${this.element_height}]`;
      }
    },

    watch:{                          
      selected_biopid: function(){
        if(this.selected_biopid){
          this.getData();
          this.getName();
        }
        else{
          this.reset();
        }
      }
    },

    methods:{

      changeSelection(selected_biopid){
        this.selected_biopid = selected_biopid;
        this.$emit('selected_biopid', this.selected_biopid)
      },

      getData(){

        const url = 'https://d2z3gst2m1gd4d.cloudfront.net/' + 
                    'biopid_summaries/summary_' +
                     this.selected_biopid + '.json';

        this.show_timer = true;

        axios
          .get(url)
          .then(response => {
            if(Object.prototype.hasOwnProperty.call(
                          response.data,'message')){
              console.log(response.data['message']);
              this.selected = '';
            }else{
              this.biopid_data = response.data;
              this.getName();
            }
          })
          .catch((err) => {
            console.error(err);
            this.server_error = err;
            this.reset();
          })
          .finally(() => {
            this.show_timer = false;
          });
      },

      getName(){

        const arr = this.habitat_list.filter(({ biopid }) => biopid === 
                    this.selected_biopid);
        this.species_name = arr[0].name;
      },

      updateContentDisplay(new_state=1){
        var old_state = this.state;
        this.state = new_state;
        if(old_state == 1 || new_state == 1){
          this.resize_map = !this.resize_map;
        }
      },

      reset(){
        this.biopid_data = null;
        this.show_timer = false;
        this.popup = null;
        this.state = 1;
      },

      updateBasemapChange(){
        this.basemap_change = !this.basemap_change;
      },

      updateFeatureOpacity(opacity){
        this.feature_opacity = opacity;
      },

      updateMap(map){
        this.map = map;
      },

      updateMapLoaded(status){
        this.map_loaded = status;
      },

      updatePopup(popup){
        this.popup = popup;
      }
    }
  }

</script>