<template>
  <div v-show="show"
       class="border-2 h-full border-neutral-100 hover:shadow--xl
             bg-hcap_blue/10  overflow-y-scroll">
    <div class="grid grid-cols-2 w-full">
      <div class="align-items-center col-span-2 grid grid-cols-12
                  justify-content-between justify-start gap-2 bg-hcap_blue">
        
        <span class="col-span-4 pl-6  h-6 text-xs text-left pt-1" 
              title="Download CSV" 
              id="download-data-two-csv">
        </span>
        <span class="col-span-4 w-full text-center text-white"
        >Habitat Components/ Patch Composition</span>
<!-- 
        <div class="h-6 col-span-1 inline text-white pr-0.5"
            style="font-variation-settings: 'wght' 700, 'GRAD' 0, 'opsz' 48;"
            id="table-expander">
          <span class="font-symbol material-symbols-outlined w-6 float-right"
                v-show="!full_screen"   
                @click="full_screen = true">fullscreen</span> 
          <span class="font-symbol material-symbols-outlined w-6 float-right"
                v-show="full_screen"
                @click="full_screen = false">fullscreen_exit</span>
        </div> -->

      </div>
      <div v-show="show_timer && !server_error"
        class="col-span-12 mt-10 inline h-8 w-8 animate-spin rounded-full
               border-4 border-solid border-current border-e-transparent 
               border-hcap_blue align-middle mx-auto
               motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
          <span class="!absolute !-m-px !h-px !w-px !overflow-hidden 
                 !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >Loading...</span >
      </div>
      <div class="h-full w-full flex items-center text-red-600"
          v-show="server_error">
        An error has occured.  Try refreshing the page.
      </div>

      <div class="card col-span-2 h-full" 
           v-show="selected_biopid && !show_timer">

           <div class="flex flex-col align-items-center justify-content-between
                        col-span-2 border-b border-hcap_logo_green py-1 bg-white">  
              <div v-if="data_available"
                class="mx-auto h-max mb-2 w-full text-base text-gray-900 
                      text-left font-bold col-span-2">   
                <ul class="flex flex-wrap items-center justify-center
                          font-normal text-blue-700 dark:text-white">
                  <li>
                      <a href="#" 
                        class="me-4 hover:underline hover:text-blue-500 
                              md:me-6"
                        :class="{'underline font-bold': dataset=='components'}"
                        data-id="components"
                        @click="updateDisplay">Components</a>
                  </li>
                  <li>
                      <a href="#" 
                        class="me-4 hover:underline hover:text-blue-500 
                              md:me-6"
                        :class="{'underline font-bold': dataset=='composition'}"
                        data-id="composition"
                        @click="updateDisplay">Composition</a>
                  </li>
                </ul>
              </div>
              <div v-if="data_available && dataset=='components'" 
              class="mx-auto pl-2 pt-2 h-max mb-2  w-full text-left text-sm 
                    font-normal col-span-2 border-hcap_logo_green border-t"
                    >Land <a class="hover:text-blue-500 underline" 
                    href='https://www.nj.gov/dep/gis/digidownload/metadata/lulc20/anderson2020.htm'
                    target="_blank">classified</a> as any one of the following 
                    by the<a href='https://gisdata-njdep.opendata.arcgis.com/datasets/2deaaa3cadd94166bdbff92a44ade284_5/about'
                    target="_blank" class="hover:text-blue-500 underline">
                    Department of Environmental Protection (NJDEP)</a> is 
                    potential {{ species_name }} habitat.</div>
              <div v-if="data_available && dataset=='components'" 
                  class="col-span-2">
                <table class="table-auto col-span-2"
                      v-show='stats'>
                  <thead>
                      <tr>
                          <th class="text-center">Code</th>
                          <th class="text-left">Description</th>
                      </tr>
                  </thead>
                  <tbody class=" font-normal">
                      <tr v-for="stat in stats"  :key="stat.lu">
                          <td class="pl-3 pr-6">{{ stat[0].lu }}</td>
                          <td class="text-sm">{{ stat[0].label }}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
        <DataTable :value="records" tableStyle="min-width: 50rem" 
                   :size="'small'"  sortMode="multiple"
                    stripedRows showGridlines selectionMode="single"
                    class="max-h-full overflow-x-scroll"
                    v-if="data_available && dataset=='composition'" >

          <template #header>
          </template>
          <Column v-for="col of columns" 
                  :key="col.field" 
                  :field="col.field" 
                  :header="col.header"
                  sortable>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

  import Column from 'primevue/column';
  import DataTable from 'primevue/datatable';
  import habitat_list from '../assets/data/habitat.json'
  import { json2csv } from 'json-2-csv';


  export default {
                
    name: 'DataTwoComponent',

    components: {
      Column,
      DataTable
    },

    props:[
      'biopid_data',
      'element_height',
      'selected_biopid',
      'server_error',
      'show_timer',
      'species_name',
      'visible'
    ],
   
    emits: [
      'state',
    ],

    watch:{

      biopid_data: function(){
        if(!this.biopid_data){
          return;
        }
        this.prepareData();
        this.createCsvLink();
      },

      full_screen: function(){
        this.$emit('state', this.full_screen?3:1);
      },

      selected_biopid: {
        handler(){
          if(this.selected_biopid){
            const arr = this.habitat_list.filter(({ biopid }) => biopid === 
                        this.selected_biopid);

            this.stats = arr[0].data;
            this.data_available = true;
          }else{
            this.stats = null;
            this.data_available = false;
          }
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      }
    },

    data(){
      return{
        columns: [
          { field: 'year', header: 'Year'},
          { field: 'lu', header: 'Code' },
          { field: 'std_label', header: 'Desc.' },
          { field: 'area', header: 'Acres' },
          { field: 'pct', header: '% of Total' }       
        ],
        csvLink: null,
        data_available: false,
        dataset: 'components',
        full_screen: false,
        habitat_list,
        header: '',
        records: null,
        show: true,
        show_link: false,
        show_menu: false,
        stats: null
      }
    },

    methods:{
    
      createCsvLink(){
        const csvContent = json2csv(this.biopid_data.use);

        const blob = new Blob([csvContent], 
                                        { type: 'text/csv;charset=utf-8,' });
        const url = URL.createObjectURL(blob);
        const linkText = document.createTextNode('Export CSV');

        let link = document.createElement('a');
        link.classList.add('w-32');
        link.classList.add('text-white');
        link.classList.add('text-xs');
        link.classList.add('hover:underline');
        link.classList.add('hover:font-bold');
        link.setAttribute('href', url);
        link.setAttribute('id', 'csv-link');
        link.setAttribute('download', 
                          this.species_name  + ' (composition).csv');
        
        link.appendChild(linkText);
        this.csvLink = link;

        const el = document.getElementById('download-data-two-csv');
        el.replaceChildren();
        el.appendChild(this.csvLink);
      },

      prepareData(){
        this.records = this.biopid_data.use;
        this.show_menu = true;
      },

      reset(){
        this.csvLink = null;
        this.records = null;
        this.show = true,
        this.show_link = false;
        this.show_menu = false;
      },

      updateDisplay(e){
        this.dataset = e.target.dataset.id;
      },
    }
  }

</script>

<style>
  .p-datatable .p-datatable-header{
    background-color: white;
  }
</style>